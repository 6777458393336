html {
    font-size: 14px;
}

body {
    font-family: "Inter", sans-serif;
}

.layout-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.layout-menu .p-tabmenu-nav {
    justify-content: center;
}

.niom-accordeon {
    position: relative;
}

.niom-accordeon-header-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.niom-accordeon-header-actions .p-button.p-button-small {
    padding: 0;
    width: 2rem;
    height: 2rem !important;
}

.niom-head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.niom-intro {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    color: #777;
}

.niom-form-element {
    width: 300px;
}

.niom-form-label {
    color: #888;
}

.niom-form-label.-small {
    font-size: .75rem;
}

@media screen and (min-width: 600px) {
    .niom-form-element {
        width: 500px;
    }
}

@media screen and (min-width: 980px) {
    .niom-form-element {
        width: 800px;
    }
}

.niom-read-zone {
    padding: 5px;
    border: 1px solid #eee;
    width: 300px;
    margin: 0 auto;
    overflow: hidden;
    height: calc(100vh - 150px);
    border-radius: 6px;
    font-size: 1.5rem;
}

.niom-read-zone.-no-overflow {
    overflow: visible;
}

.niom-read-zone.-no-height {
    height: auto;
}

.niom-read-zone.-small-text {
    font-size: 1rem;
}

.niom-read-zone .text {
    transition: transform ease 2s;
}


.niom-char {
    font-family: sans-serif;
}

.niom-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .3s, opacity .3s linear;
}

#output {
    max-width: 500px;
    font-size: 14px;
}

@media screen and (min-width: 600px) {
    .niom-read-zone {
        width: 500px;
    }
}

@media screen and (min-width: 980px) {
    .niom-read-zone {
        width: 800px;
    }
}

.niom-text-wrapper {
    position: relative;
}

.niom-end-screen {}

.niom-cgu {
    height: 60vh;
    overflow: auto;
}

.niom-cgu p {
    margin-top: 0;
}


/*
PACMAN
 */

.pacman {
    position: relative;
    width: 15px;
    height: 15px;
}

.pacman>div {
    background-color: #000;
    height: 50%;
    width: 100%;
    position: absolute;
}

.pacman-top {
    top: 0;
    border-radius: 100px 100px 0 0;
    animation: spinTop .5s infinite linear;
}

.pacman-bottom {
    bottom: 0;
    border-radius: 0 0 100px 100px;
    animation: spinBottom .5s infinite linear;
}

@keyframes spinBottom {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(35deg);
    }
}

@keyframes spinTop {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-35deg);
    }
}

.niom-stopwatch {
    font-size: 2rem;
    letter-spacing: 2px;
}

.niom-stopwatch.-float {
    font-size: 1rem;
    position: fixed;
    top: 10px;
    right: 10px;
}

.niom-word {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.niom-word.-selected {
    color: #fff;
    background: rgb(79, 70, 229);
    transform: scale(1.1);
    z-index: 1;
}

.niom-word.-selected::before {
    padding: 10px;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    content: attr(data-number);
    transform: translateX(-50%);

    background-color: rgb(79, 70, 229);
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.niom-word.-selected::after {
    content: "";
    background-color: rgb(79, 70, 229);
    height: 100%;
    width: 110%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}